import pureAxios from "axios";

// export const baseURL = "";
// export const baseURL = "http://localhost:8080/sat/api";
export const baseURL =
  process.env.BASE_URL || "https://acoesatsecuirtyprodbe.ab-inbev.com/sat/api";
// export const baseURL = `${window.location.origin}/sat/api`;

export const axios = pureAxios.create({
  baseURL,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("idToken")}`,
    },
  },
});

//TO BE USED AFTER APIS

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    // if (error?.response?.status === 401) {
    //   if (localStorage.getItem("token")) {
    //     localStorage.clear();
    //     localStorage.removeItem("idToken");
    //     localStorage.removeItem("roles");
    //     localStorage.removeItem("token");
    //     window.location.href = "/";
    //   }
    // }
    return Promise.reject(error);
  }
);
